<template>
  <div>
    <small class="text-badgeError text-xs">
      <span v-if="!customError">
        {{ text }}
      </span>
      <span v-else @click="goToRoute" v-html="getCustomErrorMessage"></span>
    </small>
  </div>
</template>

<script>
import router from "@/router";
import {mapGetters} from "vuex";

export default {
  name: "BaseError",
  props: {
    text: String,
    customError: String,
	  fieldValue: [String, Object],
  },
  computed: {
    ...mapGetters('dictionaries', ['categoriesData']),

    getCustomErrorMessage() {
      if(this.customError === 'EMAIL') {
        return this.trans('MESSAGE:EmailRegisterError')
      }
    }
  },

  methods: {
    goToRoute() {
      if(this.customError === 'EMAIL') {
        let contact = '';
        if(this.categoriesData) {
          contact = this.categoriesData.find(el => el.slug === 'contact');
        }
        router.push({name: 'post-show', params: {category: contact.slug, slug: contact.onlyPostSlug}, query: {registration: true, email: this.fieldValue}})

      }
    }
  }
}
</script>

<style lang="scss">

.emailError {
	text-decoration: underline;
}

.emailError:hover {
  opacity: theme('opacity.50');
  cursor: pointer;
}

</style>