<template>
  <ValidationProvider :rules="rules" :name="field" v-slot="{ errors }">
    <div>
      <div class="relative">
        <slot name="iconFirst"></slot>
        <t-input
            :type="fieldType ? fieldType : isPassword ? 'password' : 'text'"
            :value="val"
            @input="update"
            :name="name"
            :disabled="disabled"
            :placeholder="placeholder"
            :autocomplete="autocomplete ? 'on' : 'off'"
            :variant="errors.length > 0 ? 'badgeError' : variant"
            :tabindex="tabindex"
            @blur="$emit('blur')"
            @focus="$emit('focus')"
            :class="[customClass]"
        ></t-input>
        <base-icon name="error" v-show="errors.length > 0" :color="''" class="absolute h-5 w-5 top-1/2 transform -translate-y-1/2 right-3" alt="icon error" />
        <slot name="iconEnd"></slot>
      </div>
      <BaseError :custom-error="customError" v-if="!noError" :text="errors[0]" :fieldValue="val" />
    </div>
  </ValidationProvider>
</template>

<script>
import BaseError from "@/utils/validation/components/BaseError";
import themeConfig from "@/themeConfig";
import BaseIcon from "@/utils/icons/BaseIcon";

export default {
  name: 'TextField',
  components: {BaseIcon, BaseError},
  props: {
    value: [String, Number],
    fieldType: String,
    field: String,
    rules: String,
    placeholder: String,
    isPassword: Boolean,
    customError: String,
    noError: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
    },
    customClass: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    variant: String,
    tabindex: [String, Number],
  },
  data() {
    return {
      val: null,
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val){
        this.update(val);
      }
    }
  },
  methods: {
    update(value) {
      this.$emit('input', value)
      this.val = value;
    }
  },


}
</script>
